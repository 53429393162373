<template>
  <div class="sub-table">
    <b-table-simple style="border-radius: 8px; border: 1px solid var(--neutral-300);" responsive borderless fixed>
      <b-thead>
        <b-tr>
          <b-th width="5%">
            <Check
              v-model="checkAll"
              :value="checkAll"
              @change="checkAllOptions()"
            />
          </b-th>
          <b-th width="15%">
            <Ellipsis>Situação</Ellipsis>
          </b-th>
          <b-th width="40%">
            <Ellipsis>Exame/Procedimento</Ellipsis>
          </b-th>
          <b-th width="10%">
          </b-th>
        </b-tr>
      </b-thead>
      <template v-if="groupedItems">
        <b-tbody>
          <template v-for="groupKey in Object.keys(groupedItems)">
            <b-tr
              v-for="(item, index) in groupedItems[groupKey]"
              :key="item.id"
              :class="[
                index + 1 === groupedItems[groupKey].length
                  ? 'boundary'
                  : 'attendance'
              ]"
            >
              <template v-if="groupedItems[groupKey].length === 1 || groupKey === 'pending'">
                <b-td class="checkbox-td">
                  <label
                    class="checkbox"
                    v-if="
                    item.attendance === null ||
                    (item.attendance &&
                      !['FINISHED', 'CANCELED'].includes(
                        item.attendance.status
                      ))
                  "
                  >
                    <input
                      type="checkbox"
                      :value="item"
                      v-model="selected"
                      @click="checkOptions(item, groupKey)"
                      :disabled="isDisabled(item)"
                    />
                    <span
                      class="checkmark"
                      :class="{ disabled: isDisabled(item) }"
                    >
                    <CheckIcon />
                  </span>
                  </label>
                </b-td>
              </template>
              <template v-else-if="
                groupKey !== 'pending' &&
                index === 0 &&
                groupedItems[groupKey].length > 1"
              >
                <b-td class="grouped-check" :rowspan="groupedItems[groupKey].length">
                  <label
                    class="checkbox"
                    v-if="
                    item.attendance === null ||
                    (item.attendance &&
                      !['FINISHED', 'CANCELED'].includes(
                        item.attendance.status
                      ))
                  "
                  >
                    <input
                      type="checkbox"
                      :value="item"
                      v-model="selected"
                      @click="checkOptions(item, groupKey)"
                      :disabled="isDisabled(item)"
                    />
                    <span
                      class="checkmark"
                      :class="{ disabled: isDisabled(item) }"
                    >
                    <CheckIcon />
                  </span>
                  </label>
                </b-td>
              </template>
              <b-td class="td-body">
                <template v-if="item.attendance === null">
                  <b-button class="option pending" pill disabled>
                    <p><strong>Pendente</strong></p>
                  </b-button>
                </template>
                <template v-else>
                  <b-button
                    class="option pending"
                    v-if="
                      item.attendance.status === 'PENDING' ||
                      item.attendance.status === 'OPENED'
                    "
                    pill
                    disabled
                  >
                    <p><strong>{{ item.attendance.status === 'PENDING' ? 'Pendente' : 'Em andamento' }}</strong></p>
                  </b-button>
                  <b-button
                    class="option waiting"
                    v-if="item.attendance.status === 'PAUSED'"
                    pill
                    disabled
                  >
                    <p><strong>Pausado</strong></p>
                  </b-button>
                  <b-button
                    class="option finished"
                    v-if="item.attendance.status === 'FINISHED'"
                    pill
                    disabled
                  >
                    <p><strong>Realizado</strong></p>
                  </b-button>
                  <b-button
                    class="option canceled"
                    v-if="item.attendance.status === 'CANCELED'"
                    pill
                    disabled
                  >
                    <p><strong>Cancelado</strong></p>
                  </b-button>
                </template>
              </b-td>
              <b-td class="td-body">
                <template v-if="item.item.clinic_procedure">
                  <ProcedureLine
                    ellipsis
                    :procedure="item.item.clinic_procedure"
                    hideType
                  />
                </template>
                <template v-else>
                  <ProcedureLine ellipsis :procedure="item.item" hideType />
                </template>
              </b-td>
              <template
                v-if="item.attendance && item.attendance.status === 'FINISHED'"
              >
                <template
                  v-if="index === 0 && groupedItems[groupKey].length > 1"
                >
                  <b-td
                    class="td-body action text-right p-3"
                    :rowspan="groupedItems[groupKey].length"
                  >
                    <span>
                      <b-button
                        :id="`btn-history-${item.id}`"
                        class="clipboard-button"
                        @click="
                          openAttendanceOnNewTab(
                            `pacientes/${exam.patient_id}/exame/${item.attendance.id}`
                          )
                        "
                      >
                        <Clipboard />
                      </b-button>
                      <b-tooltip
                        :target="`btn-history-${item.id}`"
                        placement="bottom"
                      >
                        Visualizar Histórico
                      </b-tooltip>
                    </span>
                  </b-td>
                </template>
                <template v-else-if="groupedItems[groupKey].length === 1">
                  <td class="td-body action text-right p-3">
                    <span>
                      <b-button
                        :id="`btn-history-${item.id}`"
                        class="clipboard-button"
                        @click="
                          openAttendanceOnNewTab(
                            `pacientes/${exam.patient_id}/exame/${item.attendance.id}`
                          )
                        "
                      >
                        <Clipboard />
                      </b-button>
                      <b-tooltip
                        :target="`btn-history-${item.id}`"
                        placement="bottom"
                      >
                        Visualizar Histórico
                      </b-tooltip>
                    </span>
                  </td>
                </template>
              </template>

              <template
                v-if="
                  groupKey !== 'pending' &&
                  index === 0 &&
                  !['FINISHED', 'CANCELED'].includes(item.attendance.status)
                "
              >
                <td
                  :rowspan="groupedItems[groupKey].length"
                  class="text-right action td-body"
                >
                  <b-button
                    :id="`btn-refresh-${item.id}`"
                    class="refresh-button"
                    @click="
                      resetAttendanceAndAttendanceItems({
                        attendance: item.attendance.id
                      })
                    "
                  >
                    <Refresh />
                  </b-button>
                  <b-tooltip
                    :target="`btn-refresh-${item.id}`"
                    placement="bottom"
                  >
                    Reiniciar os Exames
                  </b-tooltip>
                </td>
              </template>
            </b-tr>
          </template>
        </b-tbody>
      </template>
    </b-table-simple>
  </div>
</template>

<script>
export default {
  props: {
    exam: Object
  },
  components: {
    Ellipsis: () => import('@/components/General/Ellipsis'),
    Check: () => import('@/components/General/Check'),
    ProcedureLine: () => import('@/components/General/ProcedureLine'),
    CheckIcon: () => import('@/assets/icons/check.svg'),
    Refresh: () => import('@/assets/icons/refresh.svg'),
    Clipboard: () => import('@/assets/icons/clipboard.svg')
  },
  data() {
    return {
      selected: [],
      checkAll: false,
      groupedItems: []
    }
  },
  mounted() {
    this.groupItems()
  },
  methods: {
    checkAllOptions() {
      const remaining = []
      if (this.checkAll) {
        this.selected = this.exam.appointment_items.filter(
          item => !item.attendance || item.attendance.status === 'PENDING'
        )
        this.selected.forEach(procedure => {
          remaining.push( { procedure, action: 'add' })
        })
      } else {
        this.selected.forEach(procedure => {
          remaining.push( { procedure, action: 'delete' })
        })
        this.selected = []
      }
      this.$emit('selected-exams', remaining)
    },
    groupItems() {
      const countExamsStatus = {
        PENDING: 0,
        FINISHED: 0,
        PAUSED: 0
      }
      this.groupedItems = this.exam.appointment_items.reduce((ac, it) => {
        if (it.attendance) {
          ac[it.attendance.id] === undefined
            ? (ac[it.attendance.id] = [it])
            : ac[it.attendance.id].push(it)
          countExamsStatus[it.attendance.status]++
        } else {
          ac.pending === undefined ? (ac.pending = [it]) : ac.pending.push(it)
          countExamsStatus.PENDING++
        }
        return ac
      }, {})

      return this.groupedItems
    },
    checkOptions(selected, groupKey) {
      let remaining = []
      if (!event.target.checked) {
        remaining = this.selected.filter(item => item.id === selected.id)
        if (remaining.length) {
          remaining = remaining.map(procedure => {
            return { procedure, action: 'delete' }
          })
        }
      } else {
        remaining.push({ procedure: selected, action: 'add' })
      }
      this.$emit('selected-exams', remaining)
    },
    isDisabled(procedure) {
      if (this.selected.length === 0) {
        return false
      }

      return this.selected.some(
        item => item.attendance_id !== procedure.attendance_id
      )
    },
    resetAttendanceAndAttendanceItems(data) {
      this.$swal({
        title: 'Aviso',
        html: `Essa ação é irreversível e resultará na perda de todas as informações geradas no atendimento.
        </br>
        </br>
        Deseja realmente reiniciar o(s) exame(s)?`,
        showCancelButton: true,
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true
        }).then(async res => {
        if (res.isConfirmed) {
          const l = this.$loading.show()
          this.api
            .resetAttendanceAndAttendanceItems(data)
            .then(response => {
              this.$toast.success(response.data.message)
              this.$emit('reload')
            })
            .catch(error => this.$toast.error(error.message))
            .finally(() => l.hide())
        }
      });
    },
    openAttendanceOnNewTab(url) {
      window.open(url, '_blank')
    }
  }
}
</script>
<style lang="scss">
.swal2-title {
  font-family: Nunito Sans;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  text-align: left !important;
  border-bottom: 1px solid #D9DFF2;
  padding: 20px 15px;
}

.swal2-html-container {
  font-family: Nunito Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left !important;
}

.swal2-actions {
  justify-content: end !important;
  margin: 10px 10px;  

  &:hover {
      background-image: none !important;
  }
  &:active {
    background-image: none !important;
  }

  .swal2-cancel {
    font-family: Nunito Sans;
    font-size: 14px;
    height: 40px;
    color: var(--primary-main, #305BF2) !important;
    background-color: #FFF;

    &:focus {
      box-shadow: none !important;
    }
  }

  .swal2-confirm {
    font-family: Nunito Sans;
    font-size: 14px;
    height: 40px;
    background-color: var(--primary-main, #305BF2) !important;
  }
  
}

.sub-table {
  margin: 0 1rem 1rem 1rem;
}

.sub-table tr {
  &.attendance {
    border: none;
  }

  &.boundary {
    border-bottom: 1px dashed var(--neutral-300) !important;
  }

  &.boundary:last-child {
    border: none;
  }
}

.action {
  vertical-align: middle;
}

.sub-table thead {
  background-color: var(--blue-100);
}

.checkbox-td {
  justify-content: center;

  .checkbox {
    margin-bottom: 20px !important;
    vertical-align: middle !important;
    display: flex;
    .disabled {
      background-color: #d9dff2;
      border: none;
      svg {
        display: none;
      }
    }
  }
}

.option {
  // margin-top: 10px;
  font-size: 12px;
  border: none;

  &.pending {
    background: #ffebcc;

    p {
      display: inline;
      color: #525c7a !important;
    }
  }

  &.waiting {
    background: #e2ccff;

    p {
      display: inline;
      color: #525c7a !important;
    }
  }

  &.finished {
    background: #dbfff0;

    p {
      display: inline;
      color: #525c7a !important;
    }
  }

  &.canceled {
    background: #f11828;

    p {
      display: inline;
      color: #f6f2ff !important;
    }
  }
}

.clipboard-button {
  padding: 12px 16px;
  border-radius: 8px;
  background-color: var(--blue-100);
  border: none;

  &:hover {
    background-color: var(--neutral-300);
  }

  svg {
    stroke: var(--blue-500);
  }
}

.refresh-button {
  padding: 12px 16px;
  height: 100%;
  border-radius: 8px;
  background-color: var(--blue-100);
  border: none;

  &:hover {
    background-color: var(--blue-300);
  }

  svg {
    stroke: var(--blue-500);
    fill: var(--blue-500);
  }
}

.grouped-check {
  vertical-align: middle;
  background-color: var(--neutral-100);
  border-right: 1px solid var(--blue-500);
}
</style>
